<template>
  <div>
    <MainLayout>
      <template v-slot:default>
        <!-- popup подтверждающий удаление -->

        <!-- /popup подтверждающий удаление-->
        <TableFilters :queries="queries">
          <template v-slot:filter-title>
            <span v-if="sourceObject.count">
              <span class="title-filter">Всего:</span>{{ sourceObject.count }}
            </span>
          </template>
        </TableFilters>
        <TableLayout
          :loading="isDateLoaded"
          disable-router
          :table-data="sourceObject.results"
          :header="header"
          :source-object="sourceObject"
        >
        </TableLayout>
      </template>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsAccess from "@/helpers/tableCols/access";

export default {
  name: "CardsPage",
  data() {
    return {
      loadingBtn: false,
      queries: query.access
    };
  },
  components: { TableFilters, TableLayout, MainLayout },
  computed: {
    header() {
      return tableColsAccess;
    },
    sourceObject() {
      return this.$store.getters.getPageAccessList;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    }
  },
  methods: {},
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: this.urlQuery.query
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageAccessList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPageAccessList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  }
  // beforeRouteLeave(to, from, next) {
  //   this.$store
  //       .dispatch("clearCardsPage")
  //       .then(() => {
  //         this.$store.dispatch("clearSortQuery");
  //       })
  //       .then(() => next());
  // }
};
</script>
